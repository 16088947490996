@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  :root {
    --cursor-color: black;
    --cursor-dot-size: 5px;
    --cursor-dot-size-hover: 15px;
  }

  .global-container {
    @apply flex flex-col items-center;
  }

  .content-container {
    @apply w-4/5 mx-auto;
  }

  .section-spacing {
    @apply mt-8 mb-12;
  }

  .embla__viewport {
    @apply overflow-hidden w-full;
  }

  .embla__container {
    @apply flex space-x-4 w-full;
  }
  
  .embla__slide {
    @apply flex-shrink-0 w-[calc(100%-1rem)] md:w-[calc(100%-2rem)] lg:w-[calc(100%-4rem)];
  }

  .embla__slide {
    @apply flex-shrink-0 w-full md:w-3/4 lg:w-1/2 h-auto;
  }

  .cursor-dot,
  .cursor-outline {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 1;
    pointer-events: none;
  }

  @media (max-width: 992px) {
    .cursor-dot, .cursor-outline {
      display: none;
    }
  }

  @media (max-height: 700px) {
    .button-hide {
      display: none;
    }
    .project-images {
      height: 40px !important;
      width: 40px !important;
    }
  }
}
